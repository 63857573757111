import { default as _91_46_46_46slug_93n8b2LL3PkHMeta } from "/home/bun/app/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93CHtD9X91icMeta } from "/home/bun/app/pages/articles/[...slug].vue?macro=true";
import { default as voix_45debugc3Dy79hZXTMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue?macro=true";
import { default as PreviewHVxcz5WebcMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue?macro=true";
import { default as IndexW7y91cjPBYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue?macro=true";
import { default as loginKM8BHtQEEjMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue?macro=true";
import { default as callbackcZerxeEKxDMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue?macro=true";
import { default as studiowqtmfyvkr3Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue?macro=true";
import { default as IndexckmLMvqBpkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue?macro=true";
import { default as EditG1HO8Etx8EMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue?macro=true";
import { default as CreateLeIXmd2fkwMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue?macro=true";
import { default as IndexIDbZAZtpzDMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue?macro=true";
import { default as Createw5wtdqOqOrMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue?macro=true";
import { default as TranslateIiKIxweMwYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue?macro=true";
import { default as CopylpBRpaYKgXMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue?macro=true";
import { default as Index4YWKCcvoL4Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue?macro=true";
import { default as Indexf1ItR7f7qdMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue?macro=true";
import { default as Editae48Oss9zWMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue?macro=true";
import { default as CreatebN69AIcYbHMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue?macro=true";
import { default as IndexQVnjPyG5BtMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue?macro=true";
import { default as CreateTOBLRbQQziMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue?macro=true";
import { default as EditOTvrOnndecMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue?macro=true";
import { default as ProfileQhCGEDbhPwMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue?macro=true";
import { default as indexsnvtM1ev5PMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue?macro=true";
import { default as SlicesViewvS5tmQf4xOMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicesView.vue?macro=true";
import { default as ModelsView5bEppt2CgQMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/ModelsView.vue?macro=true";
import { default as TemplateViewt3drRmJe41Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/TemplateView.vue?macro=true";
import { default as DesignGuideViewMEEc4GEGknMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/DesignGuideView.vue?macro=true";
import { default as templatizer3OuIG4HwlAMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/templatizer.vue?macro=true";
import { default as voixsxpOelNnQBMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue?macro=true";
import { default as SlicePreviewbnodGVaSj3Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicePreview.vue?macro=true";
import { default as previewaEou2GK5toMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/bun/app/pages/[...slug].vue")
  },
  {
    name: "articles-slug",
    path: "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93CHtD9X91icMeta || {},
    component: () => import("/home/bun/app/pages/articles/[...slug].vue")
  },
  {
    name: "voix-debug",
    path: "/voix-debug",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue")
  },
  {
    name: "voix-preview-release-entry-en",
    path: "/preview-release/:releaseId/:previewSecret",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue")
  },
  {
    name: "voix",
    path: "/voix",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue"),
    children: [
  {
    name: "voix-settings-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue")
  },
  {
    name: "voix-login-en",
    path: "login",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue")
  },
  {
    name: "voix-auth-callback-en",
    path: "auth/callback",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue")
  },
  {
    name: "voix-studio-en",
    path: "studio",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue")
  },
  {
    name: "voix-settings-head-tags-en",
    path: "head-tags",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue")
  },
  {
    name: "voix-settings-head-tags-edit-en",
    path: "head-tags/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue")
  },
  {
    name: "voix-settings-head-tags-create-en",
    path: "head-tags/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue")
  },
  {
    name: "voix-settings-pages-en",
    path: "pages",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue")
  },
  {
    name: "voix-settings-pages-create-en",
    path: "pages/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue")
  },
  {
    name: "voix-settings-pages-translate-en",
    path: "pages/translate",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue")
  },
  {
    name: "voix-settings-pages-copy-en",
    path: "pages/copy",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue")
  },
  {
    name: "voix-settings-releases-en",
    path: "releases",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue")
  },
  {
    name: "voix-settings-redirects-en",
    path: "redirects",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue")
  },
  {
    name: "voix-settings-redirects-edit-en",
    path: "redirects/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue")
  },
  {
    name: "voix-settings-redirects-create-en",
    path: "redirects/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue")
  },
  {
    name: "voix-settings-users-en",
    path: "users",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue")
  },
  {
    name: "voix-settings-users-create-en",
    path: "users/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue")
  },
  {
    name: "voix-settings-users-edit-en",
    path: "users/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue")
  },
  {
    name: "voix-settings-users-profile-en",
    path: "profile",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue")
  },
  {
    name: "voix-cookbook-en",
    path: "cookbook",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue")
  },
  {
    name: "voix-templatizer-en",
    path: "templatizer",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/templatizer.vue"),
    children: [
  {
    name: "templatizer-slices-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicesView.vue")
  },
  {
    name: "templatizer-models-en",
    path: "models",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/ModelsView.vue")
  },
  {
    name: "templatizer-template-en",
    path: "templates/:templateName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/TemplateView.vue")
  },
  {
    name: "templatizer-design-guide-en",
    path: "design-guide",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/DesignGuideView.vue")
  }
]
  }
]
  },
  {
    name: "templatizer-slice-en",
    path: "/voix/slice-preview/:sliceName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/templatizer/views/SlicePreview.vue")
  },
  {
    name: "cookbook-preview-en",
    path: "/voix/cookbook-preview/:recipe",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue")
  }
]